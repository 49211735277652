import { PureQueryOptions } from "@apollo/client/core";
import * as GQL from "./generated";
import { BoardType, MembershipStatus } from "./generated";

function ClubAdmins(variables: GQL.ClubAdminsQueryVariables): PureQueryOptions {
    return { query: GQL.ClubAdminsDocument, variables };
}

function ClubDetail(variables: GQL.ClubDetailQueryVariables): PureQueryOptions {
    return { query: GQL.ClubDetailDocument, variables };
}

function ClubEvents(variables: GQL.ClubEventsQueryVariables): PureQueryOptions {
    return { query: GQL.ClubEventsDocument, variables };
}

function ClubMemberships(variables: GQL.ClubMembershipsQueryVariables): PureQueryOptions {
    return { query: GQL.ClubMembershipsDocument, variables };
}

function ClubMembershipsString(): string {
    return "ClubMemberships";
}

function ClubMembershipsStatutoryBoard(variables: GQL.ClubMembershipsQueryVariables): PureQueryOptions {
    return {
        query: GQL.ClubMembershipsDocument,
        variables: { ...variables, filter: { boardType: BoardType.Main, status: [MembershipStatus.Active] } },
    };
}

function ClubMembershipsInspectionBoard(variables: GQL.ClubMembershipsQueryVariables): PureQueryOptions {
    return {
        query: GQL.ClubMembershipsDocument,
        variables: { ...variables, filter: { boardType: BoardType.Check, status: [MembershipStatus.Active] } },
    };
}

function ClubMembershipsCadastre(variables: GQL.ClubMembershipsQueryVariables): PureQueryOptions {
    return { query: GQL.ClubMembershipsDocument, variables: { ...variables, filter: { cadastre: true, limit: 999 } } };
}

function ClubMembershipsRequest(variables: GQL.ClubMembershipsQueryVariables): PureQueryOptions {
    return {
        query: GQL.ClubMembershipsDocument,
        variables: { ...variables, filter: { status: ["REQUEST"], limit: 9999 } },
    };
}

function ClubMembershipsActive(variables: GQL.ClubMembershipsQueryVariables): PureQueryOptions {
    return {
        query: GQL.ClubMembershipsDocument,
        variables: { ...variables, filter: { status: ["ACTIVE", "INVITATION"], limit: 9999 } },
    };
}

function ClubPermissions(variables: GQL.ClubPermissionsQueryVariables): PureQueryOptions {
    return { query: GQL.ClubPermissionsDocument, variables };
}

function ClubRequests(variables: GQL.ClubRequestsQueryVariables): PureQueryOptions {
    return { query: GQL.ClubRequestsDocument, variables };
}

function ClubRoles(variables: GQL.ClubRolesQueryVariables): PureQueryOptions {
    return { query: GQL.ClubRolesDocument, variables };
}

function ClubSearch(): string {
    // return { query: GQL.ClubSearchDocument, variables };
    // TODO: I think that this solution is correct in this case, because I don't know search term
    return "ClubSearch";
}

function ClubSearchCommonProfile(variables: GQL.ClubSearchCommonProfileQueryVariables): PureQueryOptions {
    return { query: GQL.ClubSearchCommonProfileDocument, variables };
}

function ClubSearchProfile(variables: GQL.ClubSearchProfileQueryVariables): PureQueryOptions {
    return { query: GQL.ClubSearchProfileDocument, variables };
}

function ClubTags(variables: GQL.ClubTagsQueryVariables): PureQueryOptions {
    return { query: GQL.ClubTagsDocument, variables };
}

function ClubTypes(variables: GQL.ClubTypesQueryVariables): PureQueryOptions {
    return { query: GQL.ClubTypesDocument, variables };
}

function ClubWall(variables: GQL.ClubWallQueryVariables): PureQueryOptions {
    return { query: GQL.ClubWallDocument, variables };
}

function ClubSurveyWall(variables: GQL.ClubSurveyWallQueryVariables): PureQueryOptions {
    return { query: GQL.ClubSurveyWallDocument, variables };
}

function Contact(variables: GQL.ContactQueryVariables): PureQueryOptions {
    return { query: GQL.ContactDocument, variables };
}

function ContactCategories(variables: GQL.ContactCategoriesQueryVariables): PureQueryOptions {
    return { query: GQL.ContactCategoriesDocument, variables };
}

function Contacts(): string {
    return "Contacts"; // TODO
}

function Defects(variables: GQL.DefectsQueryVariables): PureQueryOptions {
    return { query: GQL.DefectsDocument, variables };
}

function Defect(variables: GQL.DefectQueryVariables): PureQueryOptions {
    return { query: GQL.DefectDocument, variables };
}

function DocumentFolder(variables: GQL.DocumentFolderQueryVariables): PureQueryOptions {
    return { query: GQL.DocumentFolderDocument, variables };
}

function DocumentFolderRootFolder(variables: GQL.DocumentFolderRootFolderQueryVariables): PureQueryOptions {
    return { query: GQL.DocumentFolderRootFolderDocument, variables };
}

function Event(variables: GQL.EventQueryVariables): PureQueryOptions {
    return { query: GQL.EventDocument, variables };
}

function HouseInsolvencies(variables: GQL.HouseInsolvenciesQueryVariables): PureQueryOptions {
    return { query: GQL.HouseInsolvenciesDocument, variables };
}

function HouseSearch(variables: GQL.HouseSearchQueryVariables): PureQueryOptions {
    return { query: GQL.HouseSearchDocument, variables };
}

function LocationAutocomplete(variables: GQL.LocationAutocompleteQueryVariables): PureQueryOptions {
    return { query: GQL.LocationAutocompleteDocument, variables };
}

function LocationFind(variables: GQL.LocationFindQueryVariables): PureQueryOptions {
    return { query: GQL.LocationFindDocument, variables };
}

function MagazineArticle(variables: GQL.MagazineArticleQueryVariables): PureQueryOptions {
    return { query: GQL.MagazineArticleDocument, variables };
}

function MagazineCategory(variables: GQL.MagazineCategoryQueryVariables): PureQueryOptions {
    return { query: GQL.MagazineCategoryDocument, variables };
}

function MarketCategories(variables: GQL.MarketCategoriesQueryVariables): PureQueryOptions {
    return { query: GQL.MarketCategoriesDocument, variables };
}

function MarketProfessions(variables: GQL.MarketProfessionsQueryVariables): PureQueryOptions {
    return { query: GQL.MarketProfessionsDocument, variables };
}

function MessengerMessages(variables: GQL.MessengerMessagesQueryVariables): PureQueryOptions {
    return { query: GQL.MessengerMessagesDocument, variables };
}

function MyClubs(variables: GQL.MyClubsQueryVariables): PureQueryOptions {
    return { query: GQL.MyClubsDocument, variables };
}

function MyCreatedWall(variables: GQL.MyCreatedWallQueryVariables): PureQueryOptions {
    return { query: GQL.MyCreatedWallDocument, variables };
}

function MyEventInvitations(variables: GQL.MyEventInvitationsQueryVariables): PureQueryOptions {
    return { query: GQL.MyEventInvitationsDocument, variables };
}

function MyEvents(variables: GQL.MyEventsQueryVariables): PureQueryOptions {
    return { query: GQL.MyEventsDocument, variables };
}

function MyHouseInvitations(variables: GQL.MyHouseInvitationsQueryVariables): PureQueryOptions {
    return { query: GQL.MyHouseInvitationsDocument, variables };
}

function MyHouseRequests(variables: GQL.MyHouseReuestsQueryVariables): PureQueryOptions {
    return { query: GQL.MyHouseReuestsDocument, variables };
}

function MyHouseWall(variables: GQL.MyHouseWallQueryVariables): PureQueryOptions {
    return { query: GQL.MyHouseWallDocument, variables };
}

function MyHouses(variables: GQL.MyHousesQueryVariables): PureQueryOptions {
    return { query: GQL.MyHousesDocument, variables };
}

function MyInvitations(variables: GQL.MyInvitationsQueryVariables): PureQueryOptions {
    return { query: GQL.MyInvitationsDocument, variables };
}

function MyMessengerThreads(variables: GQL.MyMessengerThreadsQueryVariables): PureQueryOptions {
    return { query: GQL.MyMessengerThreadsDocument, variables };
}

function MyNotificationBadges(variables: GQL.MyNotificationBadgesQueryVariables): PureQueryOptions {
    return { query: GQL.MyNotificationBadgesDocument, variables };
}

function MyNotifications(variables: GQL.MyNotificationsQueryVariables): PureQueryOptions {
    return { query: GQL.MyNotificationsDocument, variables };
}

function MyProfile(variables: GQL.MyProfileQueryVariables): PureQueryOptions {
    return { query: GQL.MyProfileDocument, variables };
}

function MeNullable(variables: GQL.MeNullableQueryVariables): PureQueryOptions {
    return { query: GQL.MeNullableDocument, variables };
}

function MyVotes(variables: GQL.MyVotesQueryVariables): PureQueryOptions {
    return { query: GQL.MyVotesDocument, variables };
}

function MyWall(variables: GQL.MyWallQueryVariables): PureQueryOptions {
    return { query: GQL.MyWallDocument, variables };
}

function Partner(variables: GQL.PartnerQueryVariables): PureQueryOptions {
    return { query: GQL.PartnerDocument, variables };
}

function PartnersByProfession(variables: GQL.PartnersByProfessionQueryVariables): PureQueryOptions {
    return { query: GQL.PartnersByProfessionDocument, variables };
}

function PhotoGallery(variables: GQL.PhotoGalleryQueryVariables): PureQueryOptions {
    return { query: GQL.PhotoGalleryDocument, variables };
}

function PhotoGalleryRoot(variables: GQL.PhotoGalleryRootQueryVariables): PureQueryOptions {
    return { query: GQL.PhotoGalleryRootDocument, variables };
}

function Profile(variables: GQL.ProfileQueryVariables): PureQueryOptions {
    return { query: GQL.ProfileDocument, variables };
}

function SearchProfile(variables: GQL.SearchProfileQueryVariables): PureQueryOptions {
    return { query: GQL.SearchProfileDocument, variables };
}
function StaticDelegations(variables: GQL.StaticDelegationsQueryVariables): PureQueryOptions {
    return { query: GQL.StaticDelegationsDocument, variables };
}
function StaticDelegationsSk(variables: GQL.StaticDelegationsSkQueryVariables): PureQueryOptions {
    return { query: GQL.StaticDelegationsSkDocument, variables };
}
function Vote(variables: GQL.VoteQueryVariables): PureQueryOptions {
    return { query: GQL.VoteDocument, variables };
}
function VoteSk(variables: GQL.VoteSkQueryVariables): PureQueryOptions {
    return { query: GQL.VoteSkDocument, variables };
}
function VotePreview(variables: GQL.VotePreviewQueryVariables): PureQueryOptions {
    return { query: GQL.VotePreviewDocument, variables };
}
function VoteAnswerResults(variables: GQL.VoteAnswerResultsQueryVariables): PureQueryOptions {
    return { query: GQL.VoteAnswerResultsDocument, variables };
}
function VoteMemberships(variables: GQL.VoteMembershipsQueryVariables): PureQueryOptions {
    return { query: GQL.VoteMembershipsDocument, variables };
}

function VoteResults(variables: GQL.VoteResultsQueryVariables): PureQueryOptions {
    return { query: GQL.VoteResultsDocument, variables };
}

function Votes(variables: GQL.VotesQueryVariables): PureQueryOptions {
    return { query: GQL.VotesDocument, variables };
}

function VotesSk(variables: GQL.VotesSkQueryVariables): PureQueryOptions {
    return { query: GQL.VotesSkDocument, variables };
}

function VoteQuestions(variables: GQL.VoteQuestionsQueryVariables): PureQueryOptions {
    return { query: GQL.VoteQuestionsDocument, variables };
}

function VoteQuestionsSk(variables: GQL.VoteQuestionsSkQueryVariables): PureQueryOptions {
    return { query: GQL.VoteQuestionsSkDocument, variables };
}

function VoteCreateData(variables: GQL.VoteCreateDataQueryVariables): PureQueryOptions {
    return { query: GQL.VoteCreateDataDocument, variables };
}

function VoteCreateDataSk(variables: GQL.VoteCreateDataSkQueryVariables): PureQueryOptions {
    return { query: GQL.VoteCreateDataSkDocument, variables };
}

function WallPostLikes(variables: GQL.WallPostLikesQueryVariables): PureQueryOptions {
    return { query: GQL.WallPostLikesDocument, variables };
}

function PostmanCampaignMessageGrid(): string {
    return "PostmanCampaignMessagesGet";
}

function ServiceCompanyGetDocuments(variables: GQL.ServiceCompanyGetDocumentsQueryVariables): PureQueryOptions {
    return { query: GQL.ServiceCompanyGetDocumentsDocument, variables };
}

function ServiceCompanyGetDocumentsString(): string {
    return "ServiceCompanyGetDocuments";
}

function ServiceCompanyGetHouses(variables: GQL.ServiceCompanyGetHousesQueryVariables): PureQueryOptions {
    return { query: GQL.ServiceCompanyGetHousesDocument, variables };
}

function ServiceCompanyGetHousesString(): string {
    return "ServiceCompanyGetHouses";
}

function ServiceCompanyUnpairedPersons(variables: GQL.ServiceCompanyUnpairedPersonsQueryVariables): PureQueryOptions {
    return { query: GQL.ServiceCompanyUnpairedPersonsDocument, variables };
}
function ServiceCompanyUnpairedFlats(variables: GQL.ServiceCompanyUnpairedFlatsQueryVariables): PureQueryOptions {
    return { query: GQL.ServiceCompanyUnpairedFlatsDocument, variables };
}

function ServiceCompanyClubFlats(variables: GQL.ServiceCompanyClubFlatsQueryVariables): PureQueryOptions {
    return { query: GQL.ServiceCompanyClubFlatsDocument, variables };
}

function ServiceCompanyGet(variables: GQL.ServiceCompanyGetQueryVariables): PureQueryOptions {
    return { query: GQL.ServiceCompanyGetDocument, variables };
}

function ServiceCompanyClubMemberships(variables: GQL.ServiceCompanyClubMembershipsQueryVariables): PureQueryOptions {
    return { query: GQL.ServiceCompanyClubMembershipsDocument, variables };
}

function ServiceCompanyClubsEmployees(variables: GQL.ServiceCompanyClubsEmployeesQueryVariables): PureQueryOptions {
    return { query: GQL.ServiceCompanyClubsEmployeesDocument, variables };
}

function ServiceCompanyClubEmployees(variables: GQL.ServiceCompanyClubEmployeesQueryVariables): PureQueryOptions {
    return { query: GQL.ServiceCompanyClubEmployeesDocument, variables };
}

function ServiceCompanyEmployeesClubEmployees(
    variables: GQL.ServiceCompanyEmployeesClubEmployeesQueryVariables,
): PureQueryOptions {
    return { query: GQL.ServiceCompanyEmployeesClubEmployeesDocument, variables };
}

function AnasoftMenu(variables: GQL.AnasoftMenuQueryVariables): PureQueryOptions {
    return { query: GQL.AnasoftMenuDocument, variables };
}

function AnasoftRequests(variables: GQL.AnasoftRequestsQueryVariables): PureQueryOptions {
    return { query: GQL.AnasoftRequestsDocument, variables };
}

function HouseSkUnits(variables: GQL.HouseSkUnitsQueryVariables): PureQueryOptions {
    return { query: GQL.HouseSkUnitsDocument, variables };
}

function HouseSkOwnershipStructureLatestLog(
    variables: GQL.HouseSkOwnershipStructureLatestLogQueryVariables,
): PureQueryOptions {
    return { query: GQL.HouseSkOwnershipStructureLatestLogDocument, variables };
}

export const Refetch = {
    ClubAdmins,
    ClubDetail,
    ClubEvents,
    ClubMemberships,
    ClubMembershipsString,
    ClubMembershipsStatutoryBoard,
    ClubMembershipsInspectionBoard,
    ClubMembershipsCadastre,
    ClubMembershipsRequest,
    ClubMembershipsActive,
    ClubPermissions,
    ClubRequests,
    ClubRoles,
    ClubSearch,
    ClubSearchCommonProfile,
    ClubSearchProfile,
    ClubTags,
    ClubTypes,
    ClubWall,
    Contact,
    ContactCategories,
    Contacts,
    Defects,
    Defect,
    DocumentFolder,
    DocumentFolderRootFolder,
    Event,
    HouseInsolvencies,
    HouseSearch,
    LocationAutocomplete,
    LocationFind,
    MagazineArticle,
    MagazineCategory,
    MarketCategories,
    MarketProfessions,
    MessengerMessages,
    MyClubs,
    MyCreatedWall,
    MyEventInvitations,
    MyEvents,
    MyHouseInvitations,
    MyHouseRequests,
    MyHouseWall,
    MyHouses,
    MyInvitations,
    MyMessengerThreads,
    MyNotificationBadges,
    MyNotifications,
    MyProfile,
    MeNullable,
    MyVotes,
    MyWall,
    Partner,
    PartnersByProfession,
    PhotoGallery,
    PhotoGalleryRoot,
    Profile,
    SearchProfile,
    StaticDelegations,
    StaticDelegationsSk,
    PostmanCampaignMessageGrid,
    ServiceCompanyClubFlats,
    ServiceCompanyClubMemberships,
    ServiceCompanyClubEmployees,
    ServiceCompanyClubsEmployees,
    ServiceCompanyEmployeesClubEmployees,
    ServiceCompanyGet,
    ServiceCompanyGetDocuments,
    ServiceCompanyGetDocumentsString,
    ServiceCompanyGetHouses,
    ServiceCompanyGetHousesString,
    ServiceCompanyUnpairedFlats,
    ServiceCompanyUnpairedPersons,
    Vote,
    VoteSk,
    VotePreview,
    VoteAnswerResults,
    VoteMemberships,
    VoteResults,
    Votes,
    VoteQuestions,
    VoteCreateData,
    VoteCreateDataSk,
    WallPostLikes,
    AnasoftMenu,
    AnasoftRequests,
    VoteQuestionsSk,
    VotesSk,
    ClubSurveyWall,
    HouseSkUnits,
    HouseSkOwnershipStructureLatestLog,
};
